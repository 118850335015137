import "./AdminNavigation.css"
import { FaUsers, FaRegListAlt, FaRegCreditCard, FaRegAddressCard } from "react-icons/fa";

const AdminNavigation = ({ screenHandler }) => {
    const adminNavInfo = [
        {
            name : "Business Info",
            icon : <FaRegAddressCard/>,
            screen : "business"
        },
        {
            name : "Employees and Permissions",
            icon : <FaUsers/>,
            screen : "employee"
        },
        {
            name : "Modify menu",
            icon : <FaRegListAlt/>,
            screen : "menuEdit"
        },
        {
            name : "Payment Info (coming soon)",
            icon : <FaRegCreditCard/>,
            screen : "admin"
        },
        {
            name : "Manage Printers (coming soon)",
            icon : <FaRegListAlt/>,
            screen : "admin"
        }
    ]

    const navButtons = adminNavInfo.map((info, index) => {
        return( 
            <div key={index}>
                <div className="admin-navigation-button" onClick={() => screenHandler(info.screen)}>
                    <span className="admin-navigation-button-icon">{info.icon}</span>
                    <span className="admin-navigation-button-text">{info.name}</span>
                </div>
            </div>
        )
    })

    return(
        <>
            {navButtons}
        </>
    )
}

export default AdminNavigation;