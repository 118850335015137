import qrCode from './qrCode.svg';
import './RestaurantProfileInfo.css';
import React, { useState, useContext } from "react";

let context = React.createContext(null);

function RestaurantProfileInfo({ RestaurantProfileInfoDataJSON, widthClass }) {
  return (
        <RestaurantProfileInfoBody RestaurantProfileInfoDataJSON={RestaurantProfileInfoDataJSON} widthClass={widthClass} />
  );
}

export default RestaurantProfileInfo;

function RestaurantProfileInfoBody({ RestaurantProfileInfoDataJSON, widthClass }){
	let openStatus = "Open"
	return (
		<div className={`restaurant-profile-info-${widthClass}`}>
			<div className={`restaurant-name-${widthClass}`}>{RestaurantProfileInfoDataJSON['name']}</div><img className={`qr-code-header-${widthClass}`} src={qrCode} />
			<br />
			<div className={`restaurant-hours-${widthClass}`}><span className="restaurant-profile-open-status-open">{openStatus}</span> • {RestaurantProfileInfoDataJSON['hours']}</div>
		</div>
	)
}


// {
//     "name": "Taco Bell",
//     "hours": "Closes at 11:29 PM",
//     "distance": "4.5 mi",
// }
// Single-restaurant:
// - name
// - hours
// - distance
// - how much cheaper than DoorDash?

// Multi-restaurant:
// - category
// - ratings (average, quantity)
// - expense level