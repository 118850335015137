import "./EmployeeButton.css"
import { FaPlus } from "react-icons/fa";

const EmployeeButton = ({addNewEmployeeContentVis}) => {
    return(
        <div className="employee-navigation-button" onClick={addNewEmployeeContentVis}>
            <span className="employee-navigation-button-icon"><FaPlus/></span>
            <span className="employee-navigation-button-text">Add New Employee</span>
        </div>
    )
}

export default EmployeeButton