import logo from './logo.svg';
import './RestaurantImage.css';
import React, { useState, useContext } from "react";


function RestaurantImage({restaurantImageSrc}) {
  return (
        <RestaurantImageBody restaurantImageSrc={restaurantImageSrc}/>
  );
}

export default RestaurantImage;

function RestaurantImageBody({restaurantImageSrc}){
	return (
		<div className='restaurant-image-container'>
		    <img className='restaurant-image' src={restaurantImageSrc}/>
	    </div>
	)
}