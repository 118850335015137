import "./BusinessButton.css"

const BusinessButton = ({ children, screenHandler, screen }) => {
    return(
    	<>
	    	{	screenHandler !== undefined && 
				<span>
					<div className="business-button" onClick={() => screenHandler(screen)}>
						<p className="business-button-text">{children}</p>
					</div>
				</span>
	    	}
	    	{ screenHandler === undefined &&
				<span>
					<div className="business-button">
						<p className="business-button-text">{children}</p>
					</div>
				</span>
	    	}
        </>
    )
}

export default BusinessButton