import './SearchBar.css';
import React, { useState, useContext } from "react";

function SearchBar({ placeholderText, handleSearch }) {
	return (
		<div className="search-bar">
			<input className="search-bar-input" type="text" placeholder={ placeholderText } onKeyUp={(e) => handleSearch(e)}/>
		</div>
	)
}

export default SearchBar;