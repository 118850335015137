import ContentHeader from "../components/ContentHeader";
import CloseButton from "../components/CloseButton";
import "./EmployeeContent.css";
import EmployeeTable from "./EmployeeTable";
import React, { useState } from 'react';
import ContentWrapper from "../components/ContentWrapper";
import EmployeeButton from "./EmployeeButton";
import AddNewEmployee from "./addPage/AddNewEmployee";

const EmployeeContentScreen = ({ screenHandler, addNewEmployeeContentVis, employeeContentVis }) => {
    let employeesDataFake = [
        {
            "name": "Joey Herald",
            "role": "Server",
            "screen": "Kitchen, Server"
        },
        {
            "name": "Janet Smith",
            "role": "General Manager",
            "screen": "Floor, Kitchen, Server, Insights"
        },
        {
            "name": "James Herald",
            "role": "Server",
            "screen": "Kitchen, Server"
        },
        {
            "name": "Alice Wonderland",
            "role": "Floor manager",
            "screen": "Floor, Kitchen, Server"
        },
        {
            "name": "Robert Richards",
            "role": "Maitre d",
            "screen": "Floor, Kitchen, Server"
        }
    ];
    const parseEmployeesDataHelper = (data) => {
        let namesTmp = [];
        let rolesTmp = [];
        let screensTmp = [];
        data.forEach(d => {
            namesTmp.push(d["name"])
            rolesTmp.push(d["role"])
            screensTmp.push(d["screen"])
        });
        return {"names": namesTmp, "roles": rolesTmp, "screens": screensTmp}
    }
    const [employeesData, setEmployeesData] = useState(employeesDataFake);
    const [employeesDataTmp, setEmployeesDataTmp] = useState(employeesDataFake);
    let output = parseEmployeesDataHelper(employeesData)
    const [names, setNames] = useState(output["names"]);
    const [roles, setRoles] = useState(output["roles"]);
    const [screens, setScreens] = useState(output["screens"]);
    const [sortingKey, setSortingKey] = useState(null);
    const [sortingDirection, setSortingDirection] = useState(null);
    const parseEmployeesData = (data) => {
        let output = parseEmployeesDataHelper(data)
        setNames(output["names"]);
        setRoles(output["roles"]);
        setScreens(output["screens"]);
    }
    const sortEmployeesData = (key) => {
        if (key === null){
            setSortingKey(null);
            setSortingDirection(null);
        }
        else {
            let sortingDirectionTmp = key === sortingKey ? sortingDirection * -1 : 1;
            let employeesDataTmpTmp = [...employeesDataTmp].sort((a, b) => a[key] > b[key] ? 1 * sortingDirectionTmp : -1 * sortingDirectionTmp);
            parseEmployeesData(employeesDataTmpTmp);
            setSortingKey(key);
            setSortingDirection(sortingDirectionTmp);
            setEmployeesDataTmp(employeesDataTmpTmp);
        }
    }
    const filterEmployeesData = (key, value) => {
        let employeesDataTmpTmp = employeesData.filter(d => d[key].toLowerCase().includes(value.toLowerCase()));
        parseEmployeesData(employeesDataTmpTmp);
        setEmployeesDataTmp(employeesDataTmpTmp);
    }
    return (
        <ContentWrapper>
            {employeeContentVis && <CloseButton screenHandler={screenHandler}/>}
            <div className="employee-content-header">
                <ContentHeader headerTitle={"Employee and Permissions"}>
                    Change employee roles and set screen permissions 
                </ContentHeader>
            </div>
            <div className="employee-content-button">
                <EmployeeButton addNewEmployeeContentVis={addNewEmployeeContentVis}></EmployeeButton>
            </div>
            <div className="employee-content-info">
                <EmployeeTable format="name" search="Search name" head="Employee Name" info={names} sortEmployeesData={sortEmployeesData} sortingKey={sortingKey} sortingDirection={sortingDirection} filterEmployeesData={filterEmployeesData} />
                <EmployeeTable format="role" search="Search role" head="Role" info={roles} sortEmployeesData={sortEmployeesData} sortingKey={sortingKey} sortingDirection={sortingDirection} filterEmployeesData={filterEmployeesData} />
                <EmployeeTable format="screen" search="Search screens" head="Screen Perminssions" info={screens} sortEmployeesData={sortEmployeesData} sortingKey={sortingKey} sortingDirection={sortingDirection} filterEmployeesData={filterEmployeesData} />
            </div>
        </ContentWrapper>
    )
}

const EmployeeContent = ({screenHandler, widthClass}) => {
    const [employeeContentVis, setEmployeeContentVis] = useState(true)
    const [addEmployeeVis, setAddEmployeeVis] = useState(false)

    const employeeScreenVis = () => {
        setAddEmployeeVis(false)
        setEmployeeContentVis(true)
    }

    const addEmployeeContentVis = () => {
        setAddEmployeeVis(true)
        setEmployeeContentVis(false)
    }
    return(
        <>
            <EmployeeContentScreen 
                screenHandler={screenHandler} 
                addNewEmployeeContentVis={addEmployeeContentVis} 
                employeeContentVis={employeeContentVis}
            />
            { addEmployeeVis && <AddNewEmployee employeeScreenVis={employeeScreenVis} widthClass={widthClass}/> }
        </>
    )
}

export default EmployeeContent