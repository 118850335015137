import './ChangeOrderStatusInModalButton.css';
import React, { useState, useContext } from "react";
import { APIURL } from "./constants";

function ChangeOrderStatusInModalButton({ orderData, updateOrderStatus, oldStatus, newStatus, imageSrc, text }) {
	return (
		<div className="change-order-status-in-modal-button" onClick={updateOrderStatus(orderData, oldStatus, newStatus)}>
			<img className="change-order-status-in-modal-image" src={imageSrc} />
			<div className="change-order-status-in-modal-text"></div>
		</div>
	)
}

export default ChangeOrderStatusInModalButton;