import './MenuItemCards.css';
import { formatDollars } from './utils.js';
import React from 'react';

function MenuItemCards({ MenuItemCardData, widthClass, openModalOptions, useCase }) {
    let rows = [];
    let rowLength = widthClass === 'wide' ? 4 : 2;
    let row = [];
    MenuItemCardData.forEach(function (data, index) {
        row.push(data);
        if (index % rowLength === rowLength - 1) {
            rows.push(row);
            row = [];
        }
    });
    if (row !== []) {
        rows.push(row);
    }
    const MenuItemsRows = rows.map((data, index) => (
        <MenuItemsRow key={index} MenuItemCardData={data} widthClass={widthClass} openModalOptions={openModalOptions} useCase={useCase} />
    ));

    return <div className={'menu-item-cards-' + widthClass}>{MenuItemsRows}</div>;
}

function MenuItemsRow({ MenuItemCardData, widthClass, openModalOptions, useCase }) {
    const MenuItemCardBodies = MenuItemCardData.map((data, index) => (
        <MenuItemCardBody key={index} MenuItemCardData={data} widthClass={widthClass} openModalOptions={openModalOptions} index={index} useCase={useCase} />
    ));
    return <div className={'menu-item-row-' + widthClass}>{MenuItemCardBodies}</div>;
}

function MenuItemCardBody({ MenuItemCardData, widthClass, openModalOptions, index, useCase }) {
    let discVis = '-nodesc';
    if (MenuItemCardData['description']) {
        discVis = '';
    }
    let position = '';
    let numCols = widthClass === "narrow" ? 2 : 4;
    if (index % numCols === 0){
        position = 'left';
    }
    else if (index % numCols === numCols - 1){
        position = 'right';
    }
    else {
        position = 'inner';
    }

    const markItemUnavailable = (e) => {
        let target = e.target;
        while (target.className !== 'menu-item-card-wide' && target.className !== 'menu-item-card-narrow') {
            target = target.parentNode;
        }
        let targetWidth = target.querySelector('.menu-item-card-image-container-wide').offsetWidth;
        let overlay = target.querySelector('.menu-item-card-overlay-wide');
        let overlayDisplay = overlay.style.display;
        overlay.style.display = overlayDisplay === 'none' || overlayDisplay === '' ? 'inline' : 'none';
        overlay.style.width = String(targetWidth) + 'px';
    }
    // const resizeEventListener = () => {
    //     console.log('resize');
    //     let target = document.getElementsByClassName('menu-item-card-image-container-wide')[0];
    //     // console.log(target);
    //     console.log(target !== null);
    //     if(target !== null){
    //         let targetWidth = target.offsetWidth;
    //         // console.log(targetWidth);
    //         let overlays = document.getElementsByClassName('menu-item-card-overlay-wide');
    //         console.log('overlays:', overlays);
    //         [...overlays].forEach(function(overlay){
    //             console.log('overlay found');
    //             console.log(overlay);
    //             overlay.style.width = String(targetWidth) + 'px';
    //             console.log(overlay.style.width)
    //         });
    //     }
    // }
    // window.addEventListener('resize', function(event) {
    //     resizeEventListener();
    // }, true);
    // window.setInterval(resizeEventListener, 2000);

    if(useCase === 'restaurant-facing-app-kitchen'){
        return (
            <div className={`menu-item-card-body-${widthClass}${discVis}-${position}`} onClick={(e) => markItemUnavailable(e)}>
                <MenuItemCard MenuItemCardData={MenuItemCardData} widthClass={widthClass}></MenuItemCard>
            </div>
        );
    } else {
        return (
            <div className={`menu-item-card-body-${widthClass}${discVis}-${position}`} onClick={() => openModalOptions(MenuItemCardData)}>
                <MenuItemCard MenuItemCardData={MenuItemCardData} widthClass={widthClass}></MenuItemCard>
            </div>
        );
    }
}

function MenuItemCard({ MenuItemCardData, widthClass }) {
    return (
        <div className={'menu-item-card-' + widthClass}>
            <div className={'menu-item-card-overlay-' + widthClass}></div>
            <Image MenuItemCardData={MenuItemCardData} widthClass={widthClass} />
            <Title MenuItemCardData={MenuItemCardData} widthClass={widthClass} />
            <Price MenuItemCardData={MenuItemCardData} widthClass={widthClass} />
            <Description MenuItemCardData={MenuItemCardData} widthClass={widthClass} />
        </div>
    );
}

function Image({ MenuItemCardData, widthClass }) {
    return (
        <div className={'menu-item-card-image-container-' + widthClass}>
            {MenuItemCardData['image'] != '' ? (
                <img className={'menu-item-card-image-' + widthClass} src={MenuItemCardData['image']} />
            ) : (
                <img
                    className={'menu-item-card-image-placeholder-' + widthClass}
                    src="https://cado-assets.s3.amazonaws.com/logo.png"
                />
            )}
        </div>
    );
}

function Title({ MenuItemCardData, widthClass }) {
    return <p className={'menu-item-card-title-' + widthClass}>{MenuItemCardData['title']}</p>;
}

function Description({ MenuItemCardData, widthClass }) {
    if (MenuItemCardData['description']) {
        return <p className={'menu-item-card-description-' + widthClass}>{MenuItemCardData['description']}</p>;
    }
    return;
}

function Price({ MenuItemCardData, widthClass }) {
    return (
        <p className={'menu-item-card-price-' + widthClass}>
            ${formatDollars(MenuItemCardData['price'] === '' ? 0 : MenuItemCardData['price'])}
        </p>
    );
}

export default MenuItemCards;
