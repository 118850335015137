import "./ContentWrapper.css"

const ContentWrapper = ({ children }) => {
    return(
        <div className="content">
            {children}
        </div>
    )
}

export default ContentWrapper