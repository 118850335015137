import './Menu.css';
import React from "react";
import SectionLabel from './SectionLabel';
import MenuItemCards from './MenuItemCards';

function Menu({ MenuData, widthClass, openModalOptions, useCase }) {
  const Sections = MenuData.map((SectionData, index) =>
    <Section key={index} SectionData={SectionData} widthClass={widthClass} openModalOptions={openModalOptions} useCase={useCase} />
  );
  return (
  	<div className='menu-body'>
		{Sections}
    </div>
  );
}

function Section({ SectionData, widthClass, openModalOptions, useCase }){
	return (
		<div className='menu-section' id={SectionData["sectionName"]}>
	        <SectionLabel SectionLabelData={SectionData["sectionName"]} widthClass={widthClass} />
	        <MenuItemCards MenuItemCardData={SectionData["sectionItems"]} widthClass={widthClass} openModalOptions={openModalOptions} useCase={useCase} />
    </div>
	)
}

export default Menu;