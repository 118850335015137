import './SearchBar.css';
import React, { useState, useContext } from "react";
import { FaSearch, FaMicrophone } from "react-icons/fa";

function SearchBar({ searchMenuHelper, load, hasSearchQuery, setHasSearchQuery, useCase }) {
  return (
        <SearchBarBody searchMenuHelper={searchMenuHelper} load={load} hasSearchQuery={hasSearchQuery} setHasSearchQuery={setHasSearchQuery} useCase={useCase} />
  );
}

function SearchBarBody({ searchMenuHelper, load, hasSearchQuery, setHasSearchQuery, useCase }){
	async function searchMenu(event) {
	  if (event.key == "Enter") {
	    await load();
	    let query = document.getElementsByClassName("search-bar-input-menu-app")[0].value.toLowerCase();
	    if (query != "") {
	  	  setHasSearchQuery(true);
	      searchMenuHelper(query);
	    }
	    else {
	  	  setHasSearchQuery(false);
	    }
	  }
	  else {
	  	setHasSearchQuery(true);
	  }
	}
	function clearSearch(){
		let searchBar = document.getElementsByClassName("search-bar-input-menu-app")[0];
		searchBar.value = "";
		setHasSearchQuery(false);
		load();
	}
	return (
		<>
			<div className="search-bar-body-menu-app">
				<input type="text" className="search-bar-input-menu-app" placeholder="Search menu..." onKeyDown={searchMenu} />
				<FaSearch className={useCase === undefined ? "search-bar-menu-app-search-icon" : `search-bar-menu-app-search-icon-${useCase}`} />
				{hasSearchQuery && (
				  <button className="search-bar-menu-app-clear-search-button" onClick={clearSearch}>&times;</button>
				)}
				<FaMicrophone className="search-bar-menu-app-voice-search-button" />
			</div>
		</>
	)
}

export default SearchBar;