import "./BusinessHours.css"

const dayAndHours = [
    {
        day : "Mo",
        open : "11:00",
        close : "22:00"
    },
    {
        day : "Tue",
        open : "11:00",
        close : "22:00"
    },
    {
        day : "Wed",
        open : "11:00",
        close : "22:00"
    },
    {
        day : "Thu",
        open : "11:00",
        close : "22:00"
    },
    {
        day : "Fri",
        open : "11:00",
        close : "22:00"
    },
    {
        day : "Sat",
        open : "11:00",
        close : "22:00"
    },
    {
        day : "Sun",
        open : "11:00",
        close : "22:00"
    }
]

const BusinessHours = () => {
    function validateTime(e) {
        let target = e.target;
        var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(target.value.toLowerCase());
        if (!isValid) {
            target.style.borderColor = 'red';
        } else {
            target.style.borderColor = '#ebebeb';
        }
        console.log(isValid);
        return isValid;
    }
    const hoursInfo = dayAndHours.map((info, index) => {
        return(
            <div className="business-hour" key={index}>
                <span className="business-hour-label">{info.day}</span>
                <div className="business-table-hour-inputs">
                    <select className="business-table-hour-open-status-dropdown" name="open-status" id="open-status-dropdown">
                      <option value="Open">Open</option>
                      <option value="Closed">Closed</option>
                      <option value="Open All Day">Open All Day</option>
                    </select>
                    <input className="business-table-hour-input" placeholder="12:00" defaultValue={info.hours} onKeyUp={(e) => validateTime(e)}></input>
                    <select className="business-table-hour-ampm-dropdown" name="open-status" id="open-status-dropdown">
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                    to 
                    <input className="business-table-hour-input" placeholder="12:00" defaultValue={info.hours} onKeyUp={(e) => validateTime(e)}></input>
                    <select className="business-table-hour-ampm-dropdown" name="open-status" id="open-status-dropdown">
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                </div>
            </div>
        )
    })

    return (
        <>
            <div className="business-table-hours">
                {hoursInfo}
            </div>
        </>
    )
}

export default BusinessHours