import './ModalOrdersAggregated.css';
import ChangeOrderStatusInModalButton from './ChangeOrderStatusInModalButton';
import React, { useState, useContext } from "react";

function ModalOrdersAggregated({ closeModalOrdersAggregated, openModalOrders, ModalOrdersAggregatedData, widthClass }) {
  function switchToModalOrders(orderData){
    closeModalOrdersAggregated();
    openModalOrders(orderData);
  }
  let modalOrdersAggregatedOrdersData = [];
  ModalOrdersAggregatedData["orders"].forEach(orderData => {
    let orderDataFiltered = {};
    orderDataFiltered["orderNumber"] = orderData["orderNumber"];
    orderDataFiltered["items"] = orderData["items"].filter(dishData => dishData["title"] === ModalOrdersAggregatedData["title"]);
    modalOrdersAggregatedOrdersData.push(orderDataFiltered);
  })
  const modalOrdersAggregatedOrders = modalOrdersAggregatedOrdersData.map((orderData, index) =>
    <ModalOrdersAggregatedOrder key={index} orderData={orderData} switchToModalOrders={switchToModalOrders} />
  );
  return (
    <>
      <div onClick={closeModalOrdersAggregated} className="overlay"></div>
      <div className={`ModalOrdersAggregated-${widthClass}`}>
        <button onClick={closeModalOrdersAggregated} className="close-button">&times;</button>
        <header className={`ModalOrdersAggregated-header-${widthClass}`}>
        </header>
        <main className="ModalOrdersAggregated-main">
        <DishHeader dishData={ModalOrdersAggregatedData} />
        <OrderInfo dishData={ModalOrdersAggregatedData} />
        <GoToOrders dishData={ModalOrdersAggregatedData} switchToModalOrders={switchToModalOrders} />
        <div className="ModalOrdersAggregated-orders">
          { modalOrdersAggregatedOrders }
        </div>
        </main>
      </div>
    </>
  )
}

function DishHeader({ dishData }) {
  return (
    <h2 className="ModalOrdersAggregated-title">
      { dishData["numberOfItems"] }x { dishData["title"] }
    </h2>
  )
}

function OrderInfo({ dishData }) {
  return (
    <div className="ModalOrdersAggregated-order-info">
      { dishData["orders"].length } orders
    </div>
  )
}

function GoToOrders({ dishData, switchToModalOrders }){
  const goToOrderButtons = dishData["orders"].map((orderData, index) =>
    <GoToOrderButton key={index} orderData={orderData} switchToModalOrders={switchToModalOrders} />, 
  );
  return (
    <div className="ModalOrdersAggregated-go-to-orders">
      Go to order: {goToOrderButtons}
    </div>
  )
}

function GoToOrderButton({ orderData, switchToModalOrders }){
  return (
    <div className="ModalOrdersAggregated-go-to-order-button" onClick={() => switchToModalOrders(orderData)}>
      #{ orderData["orderNumber"] }
    </div>
  )
}

function ModalOrdersAggregatedOrder({ orderData, switchToModalOrders }){
  const modalOrdersAggregatedItems = orderData["items"].map((itemData, index) =>
    <ModalOrdersAggregatedItem key={index} itemData={itemData} />
  );
  return (
    <div className="ModalOrdersAggregated-order">
      <div className="ModalOrdersAggregated-order-number" onClick={() => switchToModalOrders(orderData)}>
        #{ orderData["orderNumber"] }
      </div>
      { modalOrdersAggregatedItems }
    </div>
  )
}

function ModalOrdersAggregatedItem({ itemData }){
  const modalOrdersAggregatedItemOptions = itemData["options"].map((optionData, index) =>
    <ModalOrdersAggregatedItemOption key={index} optionData={optionData} />
  );
  return (
    <div className="ModalOrdersAggregated-item">
      <div className="ModalOrdersAggregated-item-title-line">
        { itemData["quantity"] }x { itemData["title"] }
      </div>
      { modalOrdersAggregatedItemOptions }
    </div>
  )
}

function ModalOrdersAggregatedItemOption({ optionData }){
  const modalOrdersAggregatedItemOptionValues = optionData["value"].map((optionValueData, index) =>
    <ModalOrdersAggregatedItemOptionValue key={index} optionValueData={optionValueData} />
  );
  return (
    <div className="ModalOrdersAggregated-item-option">
      { optionData["title"] }
      { modalOrdersAggregatedItemOptionValues }
    </div>
  )
}

function ModalOrdersAggregatedItemOptionValue({ optionValueData }){
  return (
    <div className="ModalOrdersAggregated-item-option-value">
      { optionValueData["title"] }
    </div>
  )
}

export default ModalOrdersAggregated;