import './AddToOrderButton.css';
import React from 'react';

function AddToOrderButton({ widthClass, onClickFunction, price, useCase }) {
    return <AddToOrderButtonBody widthClass={widthClass} onClickFunction={onClickFunction} price={price} useCase={useCase} />;
}

export default AddToOrderButton;

function AddToOrderButtonBody({ widthClass, onClickFunction, price, useCase }) {
    return (
        <button className={ useCase === undefined ? `add-to-order-button-${widthClass}` : `add-to-order-button-${widthClass}-${useCase}` } onClick={onClickFunction}>
            <div className="add-to-order-span">{ useCase === undefined ? `Add to Order - ${price}` : "Save" }</div>
        </button>
    );
}
