import logo from './logo.svg';
import './ModalOrderConfirmation.css';
import ConfirmOrderButton from './ConfirmOrderButton';
import React, { useState, useContext, useEffect } from 'react';
import { formatDollars } from './utils.js';
import { APIURL } from './constants';

function ModalOrderConfirmation({
    closeModalOrderConfirmation,
    modalOrderConfirmationData,
    orderData,
    MenuDataIndexById,
    setOrderData,
    openModalCheckout,
    subtotal,
    tax,
    total,
    getSubtotal,
    widthClass
}) {
    function confirmOrder() {
        // const URL = APIURL + "/confirm_order"
        // const params = {
        //     order_data: orderData,
        // };
        // let options = {
        //   method: "POST",
        //   body: JSON.stringify(params),
        //   headers: { "Content-Type": "application/json" },
        // };
        // console.log(options)
        // const data = fetch(URL, options).then((res) => res.json());
        // console.log("data :" + JSON.stringify(data));
        // // if(data["status"] == "success"){
        //   setOrderData({"numberOfItems": 0, "items": []});
        closeModalOrderConfirmation();
        openModalCheckout();
        // }
    }
    return (
        <>
            <div onClick={closeModalOrderConfirmation} className="overlay"></div>
            <div className={`modalOrderConfirmation-${widthClass}`}>
                <button onClick={closeModalOrderConfirmation} className="close-button">
                    &times;
                </button>
                <main className="modalOrderConfirmation-main">
                    <h2 className="modalOrderConfirmation-title">Your Order</h2>
                    <Order orderData={orderData} MenuDataIndexById={MenuDataIndexById} getSubtotal={getSubtotal} widthClass={widthClass} />
                    <Totals subtotal={subtotal} tax={tax} total={total} />
                    {/*<OrderDataObject orderData={orderData} />*/}
                </main>
                <ConfirmOrderButton text={'Confirm Order'} widthClass={widthClass} onClickFunction={confirmOrder} />
            </div>
        </>
    );
}

function Order({ orderData, MenuDataIndexById, getSubtotal, widthClass }) {
    const orderItems = orderData['items'].map((orderItemData, index) => (
        <>
            <OrderItem
                key={'orderItems' + index}
                orderItemData={orderItemData}
                orderData={orderData}
                MenuDataIndexById={MenuDataIndexById}
                getSubtotal={getSubtotal}
                widthClass={widthClass}
            />
            <br />
        </>
    ));
    return <div className={`modalOrderConfirmation-order-${widthClass}`}>{orderItems}</div>;
}

function OrderItem({ orderItemData, orderData, MenuDataIndexById, getSubtotal, widthClass }) {
    const options = orderItemData['options'].map((data, index) => <Option key={'options' + index} optionData={data} />);
    const [quantity, setQuantity] = useState(orderItemData['quantity']);
    orderItemData['quantity'] = quantity;
    function updateQuantity(newQuantity) {
        let quantityDiff = newQuantity - quantity;
        orderData['numberOfItems'] += quantityDiff;
        if (newQuantity === 0){
            orderData["items"] = orderData["items"].filter(item => item["id"] != orderItemData["id"]);
        }
        else {
            orderItemData['quantity'] = newQuantity
            setQuantity(newQuantity);
        }
        getSubtotal(orderData);
    }
    if (quantity > 0){
        return (
            <div className="modalOrderConfirmation-order-item">
                <div className="modalOrderConfirmation-order-item-info">
                    <div className="modalOrderConfirmation-order-item-title">
                        {quantity} x {orderItemData['title']}
                    </div>
                    <div className="modalOrderConfirmation-order-item-price">
                        ${formatDollars(Math.round(orderItemData['price'] * orderItemData['quantity'] * 100) / 100)}
                    </div>
                    <br></br>
                </div>
                <br />
                <img
                    className={`modalOrderConfirmation-order-item-image-${widthClass}`}
                    src={MenuDataIndexById[orderItemData['itemId']]['image']}
                />
                <div className="modalOrderConfirmation-order-item-options">{options}</div>
                <div className="modalOrderConfirmation-order-special-instructions-title">Special Instructions</div>
                <div className="modalOrderConfirmation-order-special-instructions-value">{orderItemData["specialInstructions"]}</div>
                <QuantityButton quantity={quantity} updateQuantity={updateQuantity} />
            </div>
        );
    }
}

function QuantityButton({ quantity, updateQuantity }) {
    return (
        <div className="modalOrderConfirmation-quantityButton-container">
            <div className="modalOrderConfirmation-quantityButton">
                <button
                    className="modalOrderConfirmation-quantityButton-btn"
                    onClick={() => (quantity > 0 ? updateQuantity(quantity - 1) : null)}
                >
                    {quantity > 1 ? (
                        String.fromCharCode(8722)
                    ) : (
                        <img className="modalOrderConfirmation-quantityButton-trash-image" src="./icons8-trash-can-30.png" />
                    )}
                </button>
                <p className="modalOrderConfirmation-quantityButton-quantity">{quantity}</p>
                <button className="modalOrderConfirmation-quantityButton-btn" onClick={() => updateQuantity(quantity + 1)}>
                    &#43;
                </button>
            </div>
        </div>
    );
}

function Option({ optionData }) {
    const optionValues = optionData['value'].map((data, index) => (
        <OptionValue key={'optionValues' + index} optionValueData={data} />
    ));
    return (
        <div className="modalOrderConfirmation-order-item-option">
            <div className="modalOrderConfirmation-order-item-option-title">{optionData['title']}</div>
            {optionValues}
        </div>
    );
}

function OptionValue({ optionValueData }) {
    return (
        <div className="modalOrderConfirmation-order-item-option-value">
            <p className="modalOrderConfirmation-order-item-option-value-title">• {optionValueData['title']}</p>
        </div>
    );
}

function Totals({ subtotal, tax, total }) {
    return (
        <div className="modalOrderConfirmation-totals">
            <div className="modalOrderConfirmation-subtotal">
                <div className="modalOrderConfirmation-totals-label">
                    Subtotal
                </div>
                <div className="modalOrderConfirmation-totals-value">
                    ${ formatDollars(subtotal) }
                </div>
            </div>
            <br />
            <div className="modalOrderConfirmation-tax">
                <div className="modalOrderConfirmation-totals-label">
                    Tax
                </div>
                <div className="modalOrderConfirmation-totals-value">
                    ${ formatDollars(tax) }
                </div>
            </div>
            <br />
            <div className="modalOrderConfirmation-total-divider"></div>
            <div className="modalOrderConfirmation-total">
                <div className="modalOrderConfirmation-totals-label">
                    Total
                </div>
                <div className="modalOrderConfirmation-totals-value">
                    ${ formatDollars(total) }
                </div>
            </div>
            <br />
        </div>
    );
}

function OrderDataObject({ orderData }) {
    return (
        <div>
            { JSON.stringify(orderData, null, 4) }
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    );
}

export default ModalOrderConfirmation;
