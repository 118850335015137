import "./HorizontalSelectorOrders.css";
import React, { useState, useContext, useEffect } from "react";

function HorizontalSelectorOrders({ labelValues, orderStatus, filterOrderDatas, widthClass }) {
  const cn = (lb, st) => `horizontal-selector-orders-label${lb === st ? " active" : ""}`;

  return (
    <div className={`horizontal-selector-orders-${widthClass}`}>
      {labelValues.map((labelValue, index) => (
        <div
          key={labelValue + index}
          className={cn(labelValue, orderStatus)}
          onClick={filterOrderDatas(labelValue)}
        >
          {labelValue}
        </div>
      ))}
    </div>
  );
}

export default HorizontalSelectorOrders;
