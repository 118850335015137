import './OrdersAggregatedCard.css';
import React, { useState, useContext } from "react";

function OrdersAggregatedCard({ dishData, orderLookup, openModalOrdersAggregated }) {
	let orders = [];
	dishData["orderIDs"].forEach(orderID => orders.push(orderLookup[orderID]))
	let numberOfItems = 0;
	orders.forEach(orderData => {
		let orderDataItemsFiltered = orderData["items"].filter(itemData => itemData["title"] === dishData["title"])
		orderDataItemsFiltered.forEach(itemData => {
			numberOfItems += itemData["quantity"]
		})
	})
	let ordersAggregatedCardData = {
		title: dishData["title"],
		numberOfItems: numberOfItems,
		orders: orders
	};
	return (
		<div className="orders-aggregated-card" onClick={ () => openModalOrdersAggregated(ordersAggregatedCardData) }>
			<OrdersAggregatedCardLeft dishData={ ordersAggregatedCardData } />
			{<OrdersAggregatedCardRight dishData={ ordersAggregatedCardData } />}
			<br />
			<br />
			{/*{ JSON.stringify(ordersAggregatedCardData, null, 2) } */}
		</div>
	)
}

function OrdersAggregatedCardLeft({ dishData }) {
	return (
		<div className="orders-aggregated-card-left">
			<DishHeader dishData={ dishData } />
			<OrderInfo dishData={ dishData } />
		</div>
	)
}

function OrdersAggregatedCardRight({ dishData }) {
	let timeOfArrivals = [];
	dishData["orders"].forEach(order => timeOfArrivals.push(order["timeOfArrival"]))
	timeOfArrivals.sort()
	let timeOfArrival = timeOfArrivals[0]
	return (
		<div className="orders-aggregated-card-right">
			<OrderTimeOfArrival timeOfArrival={ timeOfArrival } />
			<OrderSetETA />
		</div>
	)
}

function DishHeader({ dishData }) {
	return (
		<div className="orders-aggregated-card-dish-header">
			{ dishData["numberOfItems"] }x { dishData["title"] }
		</div>
	)
}

function OrderInfo({ dishData }) {
	let numberOfDishes = dishData["orders"].length;
	let numberOfDishesString = numberOfDishes > 1 ? " orders" : " order";
	return (
		<div className="orders-aggregated-card-order-info">
			{ numberOfDishes + numberOfDishesString }
		</div>
	)
}

function OrderTimeOfArrival({ timeOfArrival }) {
	return (
		<div className="orders-aggregated-card-time-of-arrival">
			{ timeOfArrival }
		</div>
	)
}

function OrderSetETA() {
	return (
		<div className="orders-aggregated-card-set-eta">
			set ETA
		</div>
	)
}

export default OrdersAggregatedCard;