import './ChangeOrderStatusButton.css';
import React, { useState, useContext } from "react";
import { APIURL } from "./constants";

function ChangeOrderStatusButton({ orderData, updateOrderStatus, oldStatus, newStatus, imageSrc, text }) {
	return (
		<div className="change-order-status-button" onClick={updateOrderStatus(orderData, oldStatus, newStatus)}>
			<img className="change-order-status-image" src={imageSrc} />
			<br/>
			 <div dangerouslySetInnerHTML={{ __html: text }} />
		</div>
	)
}

export default ChangeOrderStatusButton;