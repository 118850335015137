import chevronLeft from './chevron_left.svg';
import './ModalCheckout.css';
import ConfirmOrderButton from './ConfirmOrderButton';
import React, { useState, useContext, useEffect } from "react";
import { APIURL } from "./constants";

function ModalCheckout({ closeModalCheckout, openModalOrderConfirmation, orderData, setOrderData, widthClass, load, hasSearchQuery, setHasSearchQuery }) {
  function confirmOrder(){
    let orderDataToSend = JSON.parse(JSON.stringify(orderData));
    orderDataToSend["customerName"] = document.getElementsByClassName("ModalCheckout-name")[0].value;
    if(document.getElementsByClassName("ModalCheckout-phone-number-consent")[0].checked){
      orderDataToSend["customerPhoneNumber"] = document.getElementsByClassName("ModalCheckout-phone-number")[0].value;
    };
    const URL = APIURL + "/confirm_order"
    const params = {
        order_data: orderDataToSend,
    };
    let options = {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
    };
    const data = fetch(URL, options).then((res) => res.json());
    // if(data["status"] == "success"){
      setOrderData({"numberOfItems": 0, "items": []});
      closeModalCheckout();
      load();
      let searchBar = document.getElementsByClassName("search-bar-input")[0];
      searchBar.value = "";
      setHasSearchQuery(false);
    // }
  }

  function goBack(){
    closeModalCheckout();
    openModalOrderConfirmation();
  }
  // useEffect(() => {
  //   document.getElementsByClassName('ModalCheckout-phone-number')[0].addEventListener('blur', function (e) {
  //     e.target.type = "text"
  //     var x = e.target.value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
  //     e.target.value = '(' + x[1] + ') ' + x[2] + '-' + x[3];
  //   });
  // }, []);
  // function switchToNumeric(){
  //   let target = document.getElementsByClassName('ModalCheckout-phone-number')[0]
  //   target.value.replace(" ", "")
  //   target.value.replace("-", "")
  //   target.value.replace("(", "")
  //   target.type = "number"
  // }
  return (
    <>
      <div onClick={closeModalCheckout} className="overlay"></div>
      <div className={`ModalCheckout-${widthClass}`}>
      <button onClick={goBack} className="ModalCheckout-close-button">
        <img className="ModalCheckout-chevron-left" src={chevronLeft} />
      </button>
        <main className="ModalCheckout-main">
          <h2 className="ModalCheckout-title">Checkout</h2>
          <p className="ModalCheckout-name-label ModalCheckout-input-label">
            Name
          </p>
          <input className="ModalCheckout-name ModalCheckout-input" type="text" placeholder="Alfred Winnebaker" />
          <br />
          <br />
          <p className="ModalCheckout-phone-number-label ModalCheckout-input-label">
            Phone Number
          </p>
          {/*<input className="ModalCheckout-phone-number" type="number" pattern="[0-9]*" inputmode="numeric" onClick={switchToNumeric} />*/}
          <input className="ModalCheckout-phone-number ModalCheckout-input" type="number" pattern="[0-9]*" inputmode="numeric" placeholder="5555555555" />
          {/*<input className="ModalCheckout-phone-number" type="tel" />*/}
          <input className="ModalCheckout-phone-number-consent" type="checkbox" />
          <span className="ModalCheckout-phone-number-label">By checking this box, you agree to the <a href="http://usecado.com">terms and conditions</a></span>
        </main>
        <ConfirmOrderButton text={"Checkout"} widthClass={widthClass} onClickFunction={confirmOrder} />
      </div>
    </>
  );
}

export default ModalCheckout;