import AdminContent from './adminContent/AdminContent';
import EmployeeContent from './employeePermission/EmployeeContent';
import NavigationBar from "../components/NavigationBar";
import './Admin.css';
import { useState } from 'react';
import BusinessContent from './businessInfo/BusinessContent';
import MenuApp from '@cado/self-serve-tablet/src/App';

function Admin({ setIsOpenNavigationMenu }) {
  let widthClass = window.innerWidth > 950 ? "wide" : "narrow";
  const [employeeScreen, setEmployeeScreen] = useState(false);
  const [businessScreen, setBusinessScreen] = useState(false);
  const [adminScreen, setAdminScreen] = useState(true);
  const [editMenuScreen, setEditMenuScreen] = useState(false);
  let screenSetters = {
    admin: setAdminScreen,
    business: setBusinessScreen,
    employee: setEmployeeScreen,
    menuEdit: setEditMenuScreen,
  }

  const screenHandler = (screen) => {
    console.log('screen loading')
    setEmployeeScreen(false);
    setAdminScreen(false);
    setBusinessScreen(false);
    setEditMenuScreen(false);
    screenSetters[screen](true);
  }

  const returnToAdminScreen = () => {
    screenHandler('admin')
  }

  return (
    <div className="admin">
      <NavigationBar setIsOpenNavigationMenu={setIsOpenNavigationMenu} widthClass={widthClass} />
      {adminScreen && <AdminContent 
        screenHandler={screenHandler}
      />}
      {employeeScreen && <EmployeeContent screenHandler={screenHandler} widthClass={widthClass} />}
      {businessScreen && <BusinessContent screenHandler={screenHandler} />}
      {editMenuScreen && <MenuApp useCase={"restaurant-facing-app-admin"} returnToAdminScreen={returnToAdminScreen} />}
    </div>
  );
}

export default Admin;
