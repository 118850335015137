import { useEffect, useState } from "react"
import BusinessButton from "../../components/BusinessButton"
import AddEmployeeCheck from "./AddEmployeeCheck"
import "./AddNewEmployee.css"

const AddNewEmployee = ({employeeScreenVis, widthClass}) => {
    // This controls what will be displayed for selection box and checkboxes
    const settings = {
        "role" : ["Server", "Floor Manager", "General Manager", "Maitre d"],
        "screens" : ["Kitchen", "Server", "Floor", "Insights"]
    }
    const [name, setName] = useState("") // Keeps Track of name entered
    const [role, setRole] = useState("") // Keeps track of role
    // Holds selected screen options
    let screens = []
    const [checkedItems, setCheckedItems] = useState({})

    // On Submit
    const handleSubmit = (e) => {
        e.preventDefault()

        // Check for selected checkboxes
        for(const item in checkedItems){
            if (checkedItems[item]["checked"] == true){
                screens.push(item)
            }
        }

        // create employee object
        const employee = {
            "name" : name,
            "role" : role,
            "screen" : screens
        }

        // log to console
        console.log(employee)

        // Switch screens
        employeeScreenVis()
    }

    // Handle checkboxes
    const handleCheck = (e) => {
        setCheckedItems({...checkedItems, [e.target.name]:{"checked" : e.target.checked}})
    }

    const screenOptions = settings["screens"].map((item, i) => {
        return <AddEmployeeCheck key={i} name={item} handleCheck={handleCheck}/>
    })
    const roleOptions  = settings["role"].map((item, i) => {
        return <option key={i} value={item}>{item}</option>
    })

    return(
        <>
            <div className="overlay" onClick={employeeScreenVis}></div>
            <div className={`add-employee-modal-${widthClass}`}>
                <button className="close-button" onClick={employeeScreenVis}>&times;</button>
                <form className="add-employee-table">
                    <span className="add-employee-table-label-container">
                        <h3 className="add-employee-table-label">Name</h3>
                    </span>
                    <input 
                        placeholder="Employee Name" 
                        className="add-employee-table-input" 
                        type="text" value={name} 
                        onChange={(e)=> setName(e.target.value)}
                    >
                    </input>
                    <span className="add-employee-table-label-container">
                        <h3 className="add-employee-table-label">Role</h3>
                    </span>
                    <select className="add-employee-table-select" value={role} onChange={(e) => setRole(e.target.value)}>
                        {/*<option value="" disabled selected>Select Role</option>*/}
                        {roleOptions}
                    </select>
                    <span className="add-employee-table-label-container">
                        <h3 className="add-employee-table-label">Screens</h3>
                    </span>
                    <div className="add-employee-table-screens">
                        {screenOptions}
                    </div>
                    <span onClick={(e) => handleSubmit(e)}>
                        <BusinessButton>Save</BusinessButton>
                    </span>
                </form>
            </div>
        </>
    )
}

export default AddNewEmployee