import "./BusinessInput.css" 

const BusinessInput = ({children, header}) => {
    return(
        <span className="business-table-cell">
            <h3 className="business-table-label">{header}</h3>
            <input className="business-table-input" defaultValue={children}></input>
        </span>
    )
}

export default BusinessInput