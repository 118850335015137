import ContentHeader from "../components/ContentHeader";
import ContentWrapper from "../components/ContentWrapper";
import CloseButton from "../components/CloseButton";
import BusinessButton from "../components/BusinessButton"
import "./BusinessContent.css";
import BusinessDisplay from "./BusinessDisplay";
import BusinessTable from "./BusinessTable";


const BusinessContent = ({ screenHandler }) => {
    return(
        <ContentWrapper>
            <CloseButton screenHandler={screenHandler} />
            <ContentHeader headerTitle="Business Information">
                Update contact and business information
            </ContentHeader>
            <BusinessDisplay/>
            <BusinessTable/>
            <BusinessButton screenHandler={screenHandler} screen="admin">Save</BusinessButton>
        </ContentWrapper>
    )
}

export default BusinessContent