import "./BusinessDisplay.css"

const BusinessDisplay = () => {
    return (
        <div className="business-display">
            <div className="business-display-image">
                <div className="business-display-image-text">Add picture</div>
            </div>
        </div>
    )
}

export default BusinessDisplay