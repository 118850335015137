import "./AdminContent.css"
import AdminNavigation from "./AdminNavigation";
import ContentHeader from "../components/ContentHeader"
import ContentWrapper from "../components/ContentWrapper"

const AdminContent = ({ screenHandler }) => {
    return(
        <ContentWrapper>
            <ContentHeader headerTitle={"Admin"}>
                Manage your business
            </ContentHeader>
            <div className="admin-content-navigation">
                <AdminNavigation 
                    screenHandler={screenHandler}
                />
            </div>
        </ContentWrapper>
    )
}

export default AdminContent;