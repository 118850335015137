import "./CloseButton.css"

const CloseButton = ({ screenHandler }) => {
    return(
        <button className="close-button" onClick={() => screenHandler("admin")}>
            &times;
        </button>
    )
}

export default CloseButton;