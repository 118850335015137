import './OrderCard.css';
import React, { useState, useContext } from "react";

function OrderCard({ orderData, openModalOrders }) {
	return (
		<div className="order-card" onClick={ () => openModalOrders(orderData) }>
			<OrderCardLeft orderData={ orderData } />
			<OrderCardRight orderData={ orderData } />
		</div>
	)
}

function OrderCardLeft({ orderData }) {
	return (
		<div className="order-card-left">
			<OrderNumber orderData={ orderData } />
			<OrderInfo orderData={ orderData } />
		</div>
	)
}

function OrderCardRight({ orderData }) {
	return (
		<div className="order-card-right">
			<OrderTimeOfArrival orderData={ orderData } />
			<OrderSetETA />
		</div>
	)
}

function OrderNumber({ orderData }) {
	return (
		<div className="order-card-order-number">
			Order #{ orderData["orderNumber"] }
		</div>
	)
}

function OrderInfo({ orderData }) {
	return (
		<div className="order-card-order-info">
			<span className="order-card-customer-name">
				{ orderData["customerName"] }
			</span>
			 • 
			<span className="order-card-number-of-items">
				{ orderData["numberOfItems"] }x
			</span>
		</div>
	)
}

function OrderTimeOfArrival({ orderData }) {
	return (
		<div className="order-card-time-of-arrival">
			{ orderData["timeOfArrival"] }
		</div>
	)
}

function OrderSetETA() {
	return (
		<div className="order-card-set-eta">
			set ETA
		</div>
	)
}

export default OrderCard;