import './MenuSectionScroller.css';
import React from "react";
import {Link} from "react-scroll";

function MenuSectionScroller({ MenuData, widthClass }) {
  return (
        <MenuSectionScrollerBody MenuData={MenuData} widthClass={widthClass} />
  );
}

export default MenuSectionScroller;


function MenuSectionScrollerBody({ MenuData, widthClass }){
  return (
    <div className={`menu-section-scroller-body-${widthClass}`}>
      <div className={`menu-section-scroller-body-nested-${widthClass}`}>
        <InfoCardContainer MenuData={MenuData} widthClass={widthClass}/>
      </div>
    </div>
  )
}

function InfoCardContainer({ MenuData, widthClass }){
  const InfoCards = MenuData.map((data, index) =>
    <InfoCard key={index} index={index} MenuData={MenuData} widthClass={widthClass}/>
  );
  return (
    <div className={`info-card-container-${widthClass}`}>
      {InfoCards}
    </div>
  )
}

function InfoCard({ index, MenuData, widthClass }){
  return (
    <>
      <div className={`info-card-${widthClass}`}>
        <SectionName MenuData={MenuData} index={index} ></SectionName>
      </div>
    </>
  )
}

function SectionName({ index, MenuData }){
  return (
    <span className="item-select">
      <Link to={MenuData[index]["sectionName"]} spy={true} smooth={true} offset={-155} duration={300} >
          {MenuData[index]["sectionName"]}
          <div className='underline'></div>
      </Link>
    </span>
  )
}