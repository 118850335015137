import "./EmployeeTable.css"
import { FaSistrix } from "react-icons/fa";

const EmployeeTable = ({ format, head, search, info, sortEmployeesData, sortingKey, sortingDirection, filterEmployeesData }) => {
    const employeeInfo = info.map((data, index) => {
        return <p key={index} className={`employee-content-info-${format}`}>{data}</p>
    })
    const searchFunction = (format, e) => {
        let searchInputs = document.getElementsByClassName("employee-content-search");
        searchInputs = [...searchInputs].filter(searchInput => searchInput !== e.target);
        searchInputs.forEach(searchInput => searchInput.value = "");
        filterEmployeesData(format, e.target.value);
    }
    return(
        <form>
            <div className={`employee-content-info-head-wrapper-${format}`}>
                <div className="employee-content-info-head" onClick={() => sortEmployeesData(format)}>
                    <h2 className="employee-content-info-head-head">{head}</h2>
                    <svg className="employee-content-info-head-icon" width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        {((sortingKey !== format) || (sortingDirection === 1)) ?
                            <path d="M1.1543 6.30317L5.64148 2.23077V13.7692" stroke="#838B93" strokeWidth="1.5"/> : <></>
                        }}
                        {((sortingKey !== format) || (sortingDirection === -1)) ?
                            <path d="M14.1279 9.69664L9.64075 13.769V2.23058" stroke="#838B93" strokeWidth="1.5"/> : <></>
                        }}
                    </svg>
                </div>
                <div className="employee-content-info-search" onKeyUp={(e) => searchFunction(format, e)}>
                    <FaSistrix className="employee-content-info-search-icon"/>
                    <input className="employee-content-search" placeholder={search} type="text"></input>
                </div>
                <div className="employee-content-info-wrapper">
                    {employeeInfo}
                </div>
            </div>
        </form>
    )
}

export default EmployeeTable