import "./NavigationMenu.css";
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

function NavigationMenu({ closeNavigationMenu, screen }) {
  return (
    <>
    <div onClick={closeNavigationMenu} className="overlay"></div>
    <div className="navigation-menu">
      <div className="navigation-menu-close" onClick={closeNavigationMenu}>
        &times;
      </div>
      <div className="navigation-menu-content">
        <Link to="/" className="navigation-menu-link" onClick={closeNavigationMenu}>
          <svg className="navigation-menu-kitchen-icon" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2454 19.9043H13.332V10.5865H20.7363C21.1957 10.5865 21.5683 10.214 21.5683 9.75461V5.63643C21.5683 5.177 21.1957 4.80444 20.7363 4.80444H4.26372C3.80429 4.80444 3.43173 5.177 3.43173 5.63643V9.75461C3.43173 10.214 3.80429 10.5865 4.26372 10.5865H11.668V19.9043H9.75463C9.29512 19.9043 8.92264 20.2768 8.92264 20.7363C8.92264 21.1957 9.29512 21.5682 9.75463 21.5682H15.2454C15.7048 21.5682 16.0774 21.1957 16.0774 20.7363C16.0774 20.2768 15.7048 19.9043 15.2454 19.9043ZM5.09562 6.46842H19.9044V8.92262H5.09562V6.46842Z" fill="#525F6A"/>
            <path d="M0 20.7362C0 21.1956 0.372559 21.5682 0.831987 21.5682C1.29141 21.5682 1.66389 21.1956 1.66389 20.7362V16.0773H6.86355V20.7362C6.86355 21.1956 7.23611 21.5682 7.69554 21.5682C8.15497 21.5682 8.52744 21.1956 8.52744 20.7362V12.4999C8.52744 12.0405 8.15497 11.6679 7.69554 11.6679H1.66389V4.26363C1.66389 3.8042 1.29141 3.43164 0.831987 3.43164C0.372559 3.43164 0 3.8042 0 4.26363V20.7362ZM1.66389 13.3319H6.86355V14.4135H1.66389V13.3319Z" fill="#525F6A"/>
            <path d="M16.4725 20.7362C16.4725 21.1956 16.845 21.5682 17.3045 21.5682C17.7639 21.5682 18.1364 21.1956 18.1364 20.7362V16.0773H23.3361V20.7362C23.3361 21.1956 23.7086 21.5682 24.168 21.5682C24.6274 21.5682 25 21.1956 25 20.7362V4.26363C25 3.8042 24.6274 3.43164 24.168 3.43164C23.7086 3.43164 23.3361 3.8042 23.3361 4.26363V11.6679H17.3045C16.845 11.6679 16.4725 12.0405 16.4725 12.4999V20.7362V20.7362ZM18.1364 14.4134V13.3318H23.3361V14.4134H18.1364Z" fill="#525F6A"/>
          </svg>
          <div className="navigation-menu-kitchen-label">
            Kitchen
          </div>
        </Link>
        <Link to="/admin" className="navigation-menu-link" onClick={closeNavigationMenu}>
          <svg className="navigation-menu-admin-icon" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2454 19.9043H13.332V10.5865H20.7363C21.1957 10.5865 21.5683 10.214 21.5683 9.75461V5.63643C21.5683 5.177 21.1957 4.80444 20.7363 4.80444H4.26372C3.80429 4.80444 3.43173 5.177 3.43173 5.63643V9.75461C3.43173 10.214 3.80429 10.5865 4.26372 10.5865H11.668V19.9043H9.75463C9.29512 19.9043 8.92264 20.2768 8.92264 20.7363C8.92264 21.1957 9.29512 21.5682 9.75463 21.5682H15.2454C15.7048 21.5682 16.0774 21.1957 16.0774 20.7363C16.0774 20.2768 15.7048 19.9043 15.2454 19.9043ZM5.09562 6.46842H19.9044V8.92262H5.09562V6.46842Z" fill="#525F6A"/>
            <path d="M0 20.7362C0 21.1956 0.372559 21.5682 0.831987 21.5682C1.29141 21.5682 1.66389 21.1956 1.66389 20.7362V16.0773H6.86355V20.7362C6.86355 21.1956 7.23611 21.5682 7.69554 21.5682C8.15497 21.5682 8.52744 21.1956 8.52744 20.7362V12.4999C8.52744 12.0405 8.15497 11.6679 7.69554 11.6679H1.66389V4.26363C1.66389 3.8042 1.29141 3.43164 0.831987 3.43164C0.372559 3.43164 0 3.8042 0 4.26363V20.7362ZM1.66389 13.3319H6.86355V14.4135H1.66389V13.3319Z" fill="#525F6A"/>
            <path d="M16.4725 20.7362C16.4725 21.1956 16.845 21.5682 17.3045 21.5682C17.7639 21.5682 18.1364 21.1956 18.1364 20.7362V16.0773H23.3361V20.7362C23.3361 21.1956 23.7086 21.5682 24.168 21.5682C24.6274 21.5682 25 21.1956 25 20.7362V4.26363C25 3.8042 24.6274 3.43164 24.168 3.43164C23.7086 3.43164 23.3361 3.8042 23.3361 4.26363V11.6679H17.3045C16.845 11.6679 16.4725 12.0405 16.4725 12.4999V20.7362V20.7362ZM18.1364 14.4134V13.3318H23.3361V14.4134H18.1364Z" fill="#525F6A"/>
          </svg>
          <div className="navigation-menu-admin-label">
            Admin
          </div>
        </Link>
      </div>
    </div>
    </>
  )
}

export default NavigationMenu;
