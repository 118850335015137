import "./App.css";
import Kitchen from './kitchen/Kitchen';
import Admin from './admin/Admin';
import NavigationMenu from './components/NavigationMenu';
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router,Routes, Route, Link, useLocation } from 'react-router-dom';

function App() {

  const [isOpenNavigationMenu, setIsOpenNavigationMenu] = useState(false);
  const [NavigationMenuData, setNavigationMenuData] = useState({});
  const openNavigationMenu = (NavigationMenuData) => {
    setNavigationMenuData(NavigationMenuData);
    setIsOpenNavigationMenu(true);
  };

  const closeNavigationMenu = () => {
    setIsOpenNavigationMenu(false);
  };

  const getScreen = () => {
    let screen=document.location.href.split('/').slice(-1)[0];
    screen = screen === "" ? "kitchen" : screen;
    return screen;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path='/' element={<Kitchen setIsOpenNavigationMenu={setIsOpenNavigationMenu} screen={getScreen()} />}></Route>
          <Route exact path='/admin' element={<Admin setIsOpenNavigationMenu={setIsOpenNavigationMenu} screen={getScreen()} />}></Route>
        </Routes>
        {isOpenNavigationMenu && (
          <NavigationMenu
            closeNavigationMenu={closeNavigationMenu}
            screen={getScreen()}
          />
        )}
      </div>
    </Router>
  );
}

export default App;
