import "./AddEmployeeCheck.css"

const AddEmployeeCheck = ({name, handleCheck}) => {
    return (
        <label className="employee-checkbox-label">
            <input className="employee-checkbox-input" name={name} type="checkbox" onChange={handleCheck}></input>
            {name}
        </label>
    )
}
export default AddEmployeeCheck