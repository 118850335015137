import logo from './logo.svg';
import './SectionLabel.css';
import React, { useState, useContext } from "react";


function SectionLabel({ SectionLabelData, widthClass }) {
  return (
        <SectionLabelBody SectionLabelData={SectionLabelData} widthClass={widthClass} />
  );
}

export default SectionLabel;

function SectionLabelBody({ SectionLabelData, widthClass }){
	return (
		<p class={`section-label-${widthClass}`}>{SectionLabelData}</p>
	)
}