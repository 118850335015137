import BusinessHours from "./BusinessHours"
import BusinessInput from "./BusinessInput"
import "./BusinessTable.css"

const BusinessTable = () => {
    return(
        <form className="business-form">
            <div className="business-table">
                <div className="business-table-one">
                    <BusinessInput header="Business Name">Business name here</BusinessInput>
                    <BusinessInput header="Description">Classic bistro serving French food</BusinessInput>
                    <BusinessInput header="Owner">Gustav Purpleson</BusinessInput>
                </div>
                <div className="business-table-two">
                    <BusinessInput header="Street Address">94 Prince St</BusinessInput>
                    <BusinessInput header="City">New York</BusinessInput>
                    <div className="business-table-zip">
                        <div className="business-table-state-holder">
                            <BusinessInput header="State">NY</BusinessInput>
                        </div>
                        <div className="business-table-zip-holder">  
                            <BusinessInput header="Zip">10012</BusinessInput>
                        </div>
                    </div>
                </div>
                <div className="business-table-three">
                    <h3 className="business-table-label">Hours</h3>
                    <BusinessHours/>
                </div>
            </div>
        </form>
    )
}

export default BusinessTable