import './ConfirmOrderButton.css';
import React from "react";

function ConfirmOrderButton({ text, widthClass, onClickFunction }) {
  return (
        <ConfirmOrderButtonBody text={text} widthClass={widthClass} onClickFunction={onClickFunction} />
  );
}

export default ConfirmOrderButton;

function ConfirmOrderButtonBody({ text, widthClass, onClickFunction }){
	return (
		<button className={`confirm-order-button-${widthClass}`} onClick={onClickFunction}>
			<div className="confirm-order-span">{ text }</div>
		</button>
	)
}