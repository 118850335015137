import "./Kitchen.css";
import NavigationBar from "../components/NavigationBar";
import OrdersGeneric from "./OrdersGeneric";
import OrdersAggregated from "./OrdersAggregated";
import ModalOrders from "./ModalOrders";
import ModalOrdersAggregated from "./ModalOrdersAggregated";
import React, { useState, useEffect } from "react";
import { APIURL } from "./constants";
import MenuApp from '@cado/self-serve-tablet/src/App';

function updateObject(object, key, value) {
  if (key in object) {
    object[key].push(value);
  } else {
    object[key] = [value];
  }
}

function updateObjectTwoDeep(object, key1, key2, value) {
  if (key1 in object && key2 in object[key1]) {
    object[key1][key2].push(value);
  } else if (key1 in object) {
    object[key1][key2] = [value];
  } else {
    object[key1] = {};
    object[key1][key2] = [value];
  }
}

function Kitchen({ setIsOpenNavigationMenu}) {
  let widthClass = window.innerWidth > 950 ? "wide" : "narrow";
  const [orderStatus, setOrderStatus] = useState("new");

  const [itemAvailabilityModalIsOpen, setItemAvailabilityModalIsOpen] = useState(false);

  const toggleItemAvailabilityModal = () => {
    setItemAvailabilityModalIsOpen(!itemAvailabilityModalIsOpen)
  }

  const [isOpenModalOrders, setIsOpenModalOrders] = useState(false);
  const [ModalOrdersData, setModalOrdersData] = useState({});
  const openModalOrders = (ModalOrdersData) => {
    setModalOrdersData(ModalOrdersData);
    setIsOpenModalOrders(true);
  };

  const closeModalOrders = () => {
    setIsOpenModalOrders(false);
  };

  const [isOpenModalOrdersAggregated, setIsOpenModalOrdersAggregated] = useState(false);
  const [ModalOrdersAggregatedData, setModalOrdersAggregatedData] = useState({});
  const openModalOrdersAggregated = (ModalOrdersAggregatedData) => {
    setModalOrdersAggregatedData(ModalOrdersAggregatedData);
    setIsOpenModalOrdersAggregated(true);
  };

  const closeModalOrdersAggregated = () => {
    setIsOpenModalOrdersAggregated(false);
  };

  const [orderDatasOriginal, setOrderDatasOriginal] = useState([]);
  const [orderDatas, setOrderDatas] = useState([]);
  const [orderLookup, setOrderLookup] = useState({});
  const [orderDatasAggregated, setOrderDatasAggregated] = useState({});

  const loadOrders = async () => {
    const URL = APIURL + "/get_orders";
    let options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    const data = await fetch(URL, options).then((res) => res.json());
    setOrderDatasOriginal(data);
  };

  useEffect(() => {
    if (!!orderDatasOriginal.length) {
      setOrderDatas(orderDatasOriginal.filter((orderData) => orderData["status"] == orderStatus));
      setOrderLookupAndOrderDatasAggregated(orderDatasOriginal);
    }
  }, [orderStatus, orderDatasOriginal]);

  useEffect(() => {
    (function refresh() {
      loadOrders();
      setTimeout(refresh, 200);
    })();
  }, []);

  function setOrderLookupAndOrderDatasAggregated(data) {
    let orderLookupTmp = {};
    data.forEach((x) => (orderLookupTmp[x["orderNumber"]] = x));
    setOrderLookup(orderLookupTmp);
    let orderDatasAggregatedTmp = {};
    data.forEach((x) =>
      x["items"].forEach((item) => {
        updateObjectTwoDeep(orderDatasAggregatedTmp, x["status"], item["title"], x["orderNumber"]);
      })
    );
    setOrderDatasAggregated(orderDatasAggregatedTmp);
  }

  const filterOrderDatas = (status) => (e) => {
    setOrderStatus(status);
    Array.from(
      document.getElementsByClassName("horizontal-selector-orders-aggregated-label")
    ).forEach((label) => label.classList.remove("active"));
    e.target.classList.add("active");
  };

  const updateOrderStatus = (orderData, oldStatus, newStatus) => (e) => {
    orderData["status"] = newStatus;
    const URL = APIURL + "/update_order";
    const params = {
      order_data: orderData,
    };
    let options = {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
    };
    const data = fetch(URL, options).then((res) => res.json());
    setOrderDatas(orderDatasOriginal.filter((orderData) => orderData["status"] === orderStatus));
    setOrderLookupAndOrderDatasAggregated(orderDatas);
  };

  if (widthClass === "narrow") {
    return (
      <div className="kitchen">
        <NavigationBar setIsOpenNavigationMenu={setIsOpenNavigationMenu} widthClass={widthClass} />
        <div className="switch-device-disclaimer">
          Landscape orientation on ipad, large tablet, or desktop is currently required to view the Kitchen screen.
          <br />
          <br />
          Please view in landscape on one of these devices.
        </div>
      </div>
    );
  } else {
    return (
      <div className="kitchen">
        <NavigationBar setIsOpenNavigationMenu={setIsOpenNavigationMenu} widthClass={widthClass}  toggleItemAvailabilityModal={toggleItemAvailabilityModal} itemAvailabilityModalIsOpen={itemAvailabilityModalIsOpen} />
        { !itemAvailabilityModalIsOpen && 
          <>
            <OrdersGeneric
              orderDatas={orderDatas}
              orderDatasOriginal={orderDatasOriginal}
              openModalOrders={openModalOrders}
              setOrderDatas={setOrderDatas}
              orderStatus={orderStatus}
              setOrderStatus={setOrderStatus}
              filterOrderDatas={filterOrderDatas}
              updateOrderStatus={updateOrderStatus}
              widthClass={widthClass}
            />
            <OrdersAggregated
              orderDatasAggregated={orderDatasAggregated}
              openModalOrdersAggregated={openModalOrdersAggregated}
              orderDatas={orderDatas}
              orderStatus={orderStatus}
              filterOrderDatas={filterOrderDatas}
              updateOrderStatus={updateOrderStatus}
              orderLookup={orderLookup}
              widthClass={widthClass}
            />
          </>
        }
        { itemAvailabilityModalIsOpen &&
          <div className="menu-app-container">
            <MenuApp useCase="restaurant-facing-app-kitchen" toggleItemAvailabilityModal={toggleItemAvailabilityModal} />
          </div>
        }
        {isOpenModalOrders && (
          <ModalOrders
            closeModalOrders={closeModalOrders}
            ModalOrdersData={ModalOrdersData}
            updateOrderStatus={updateOrderStatus}
            widthClass={widthClass}
          />
        )}
        {isOpenModalOrdersAggregated && (
          <ModalOrdersAggregated
            closeModalOrdersAggregated={closeModalOrdersAggregated}
            openModalOrders={openModalOrders}
            ModalOrdersAggregatedData={ModalOrdersAggregatedData}
            widthClass={widthClass}
          />
        )}
      </div>
    );
  }
}

export default Kitchen;
