import "./ContentHeader.css"

const ContentHeader = ({ headerTitle, children }) => {
    return(
        <>
            <h1 className="content-header-head">{headerTitle}</h1>
            <p className="content-header-text">
                {children}
            </p>
        </>
    )
}

export default ContentHeader