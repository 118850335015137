import './ModalOrders.css';
import ChangeOrderStatusInModalButton from './ChangeOrderStatusInModalButton';
import React, { useState, useContext } from "react";

function ModalOrders({ closeModalOrders, ModalOrdersData, updateOrderStatus, widthClass }) {
  const modalOrdersItems = ModalOrdersData["items"].map((itemData, index) =>
    <ModalOrdersItem key={index} itemData={itemData} />
  );
  let changeStatusInModalButtons = [];
  if(ModalOrdersData["status"] === "new") {
    changeStatusInModalButtons.push(
      <ChangeOrderStatusInModalButton key="1" orderData={ModalOrdersData} updateOrderStatus={updateOrderStatus} oldStatus={"new"} newStatus={"in-progress"} imageSrc={"./start_order_button_half_circle.png"} text={"Mark in-progress"} />
    );
    changeStatusInModalButtons.push(
      <ChangeOrderStatusInModalButton key="2" orderData={ModalOrdersData} updateOrderStatus={updateOrderStatus} oldStatus={"new"} newStatus={"needs follow-up"} imageSrc={"./icons8-notepad-30.png"} text={"Request follow-up"} />
    );
    changeStatusInModalButtons.push(
      <ChangeOrderStatusInModalButton key="3" orderData={ModalOrdersData} updateOrderStatus={updateOrderStatus} oldStatus={"new"} newStatus={"completed"} imageSrc={"./green_check.png"} text={"Mark complete"} />
    );
  }
  else if(ModalOrdersData["status"] === "in-progress") {
    changeStatusInModalButtons.push(
      <ChangeOrderStatusInModalButton key="1" orderData={ModalOrdersData} updateOrderStatus={updateOrderStatus} oldStatus={"in-progress"} newStatus={"new"} imageSrc={"./icons8-new-64.png"} text={"Mark new"} />
    );
    changeStatusInModalButtons.push(
      <ChangeOrderStatusInModalButton key="2" orderData={ModalOrdersData} updateOrderStatus={updateOrderStatus} oldStatus={"in-progress"} newStatus={"needs follow-up"} imageSrc={"./icons8-notepad-30.png"} text={"Request follow-up"} />
    );
    changeStatusInModalButtons.push(
      <ChangeOrderStatusInModalButton key="3" orderData={ModalOrdersData} updateOrderStatus={updateOrderStatus} oldStatus={"in-progress"} newStatus={"completed"} imageSrc={"./green_check.png"} text={"Mark complete"} />
    );
  }
  else if(ModalOrdersData["status"] === "needs follow-up") {
    changeStatusInModalButtons.push(
      <ChangeOrderStatusInModalButton key="1" orderData={ModalOrdersData} updateOrderStatus={updateOrderStatus} oldStatus={"needs follow-up"} newStatus={"new"} imageSrc={"./icons8-new-64.png"} text={"Mark new"} />
    );
    changeStatusInModalButtons.push(
      <ChangeOrderStatusInModalButton key="2" orderData={ModalOrdersData} updateOrderStatus={updateOrderStatus} oldStatus={"needs follow-up"} newStatus={"in-progress"} imageSrc={"./start_order_button_half_circle.png"} text={"Mark in-progress"} />
    );
    changeStatusInModalButtons.push(
      <ChangeOrderStatusInModalButton key="3" orderData={ModalOrdersData} updateOrderStatus={updateOrderStatus} oldStatus={"needs follow-up"} newStatus={"completed"} imageSrc={"./green_check.png"} text={"Mark complete"} />
    );
  }
  else if(ModalOrdersData["status"] === "completed") {
    changeStatusInModalButtons.push(
      <ChangeOrderStatusInModalButton key="1" orderData={ModalOrdersData} updateOrderStatus={updateOrderStatus} oldStatus={"completed"} newStatus={"new"} imageSrc={"./icons8-new-64.png"} text={"Mark new"} />
    );
    changeStatusInModalButtons.push(
      <ChangeOrderStatusInModalButton key="2" orderData={ModalOrdersData} updateOrderStatus={updateOrderStatus} oldStatus={"completed"} newStatus={"in-progress"} imageSrc={"./start_order_button_half_circle.png"} text={"Mark in-progress"} />
    );
    changeStatusInModalButtons.push(
      <ChangeOrderStatusInModalButton key="3" orderData={ModalOrdersData} updateOrderStatus={updateOrderStatus} oldStatus={"completed"} newStatus={"needs follow-up"} imageSrc={"./icons8-notepad-30.png"} text={"Request follow-up"} />
    );
  }
  return (
    <>
      <div onClick={closeModalOrders} className="overlay"></div>
      <div className={`ModalOrders-${widthClass}`}>
        <button onClick={closeModalOrders} className="close-button">&times;</button>
        <header className={`ModalOrders-header-${widthClass}`}>
        </header>
        <main className="ModalOrders-main">
          {/*<h2 className="ModalOrders-title">{ModalOrdersData["title"]}</h2>*/}
          <h2 className="ModalOrders-title">Order #{ ModalOrdersData["orderNumber"] }</h2>
          <div className="ModalOrders-customer"><span className="ModalOrders-label">Customer</span>{ ModalOrdersData["customerName"] }</div>
          <div className="ModalOrders-number-of-items">{ ModalOrdersData["numberOfItems"] } items</div>
{/*          <div className="ModalOrders-item">1x Beet Salad</div>
          <div className="ModalOrders-item">1x Porterhouse</div>
          <div className="ModalOrders-item">1x Carbonara</div>*/}
          { modalOrdersItems }
          <br />
          <div className="change-order-status-in-modal-button-container">
            { changeStatusInModalButtons }
          </div>
        </main>
      </div>
    </>
  )
}

function ModalOrdersItem({ itemData }){
  const modalOrdersItemOptions = itemData["options"].map((optionData, index) =>
    <ModalOrdersItemOption key={index} optionData={optionData} />
  );
  return (
    <div className="ModalOrders-item">
      <div className="ModalOrders-item-title-line">
        { itemData["quantity"] }x { itemData["title"] }
      </div>
      { modalOrdersItemOptions }
    </div>
  )
}

function ModalOrdersItemOption({ optionData }){
  const modalOrdersItemOptionValues = optionData["value"].map((optionValueData, index) =>
    <ModalOrdersItemOptionValue key={index} optionValueData={optionValueData} />
  );
  return (
    <div className="ModalOrders-item-option">
      { optionData["title"] }
      { modalOrdersItemOptionValues }
    </div>
  )
}

function ModalOrdersItemOptionValue({ optionValueData }){
  return (
    <div className="ModalOrders-item-option-value">
      { optionValueData["title"] }
    </div>
  )
}

export default ModalOrders;